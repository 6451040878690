/* App */

@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
body,
* {
  font-family: Roboto;
  margin: 0;
  line-height: 1.5;
}

a {
  text-decoration: none;
}

.f-12,
.f-12 span {
  font-size: 12px;
}

.f-16 {
  font-size: 16px;
}

.f-14,
.f-14 span {
  font-size: 14px;
}

.f-20 {
  font-size: 20px;
}

.f-17-only {
  font-size: 17px;
}

.f-17 {
  font-size: 17px;
  line-height: 18px;
}

.f-18 {
  font-size: 18px;
  line-height: 19px;
}

.wrapper {
  display: grid;
}

*,
::after,
::before {
  box-sizing: border-box;
  outline: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.toggle-show-password {
  align-items: center;
}

@media (max-width: 490px) {
  .d-postmd-none {
    display: none;
  }
}

.register {
  margin-right: 15px;
}

@media (max-width: 1024px) {
  .side-nav.open ~ .content:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 1024px) {
  .side-nav .toogle-sideBar {
    display: none;
  }
  .register span:after,
  .login span:after {
    display: none;
  }
}

@media (max-width: 800px) {
  .side-nav:not(.open) a {
    pointer-events: none;
  }
}

@media (max-width: 992px) {
  .d-premd-none {
    display: none;
  }
  .header-stub {
    display: block;
  }
}

@media (min-width: 993px) {
  .header-stub {
    display: none;
  }
}

@media (max-width: 958px) and (min-width: 490px) {
  .d-premd-block {
    display: block;
  }
}

.action-counter {
  background-color: #a038e3;
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 14px;
  text-align: center;
}
@-moz-document url-prefix() {
  .action-counter span {
    left: -1px !important;
  }
}
.action-counter span {
  font-style: italic;
  font-weight: 900;
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 17px;
  margin: auto;
}

.action-counter .plus {
  top: -7px;
  right: -23px;
  color: #a038e3;
}

/* App end */

@media (min-width: 800px) {
  .download_block {
    margin-top: 60px;
  }
}

/* Media */

@media (min-width: 768px) and (max-width: 992px) {
  .fixed760 {
    min-width: 270px;
  }
  .search-tabs {
    font-size: 20px;
  }
}

.search-tabs {
  margin: 0 !important;
}
.about-page a {
  color: #9509ef;
}
.lk-page .about-page {
  border-top: none;
}

.lk-page .articles-header {
  padding-top: 6px;
  padding-bottom: 6px;
}

.lk-page .articles-header div {
  font-size: 14px;
  line-height: 16px;
}
.articles-header .row > div span {
  position: relative;
  width: max-content;
}
.articles-header .row > div > span > svg:not(.down):not(.up) {
  opacity: 0;
  transition: all 0.3s;
}
.articles-header .row > div > span > svg.up {
  transform: rotate(180deg);
}
.articles-header .row > div:hover > span > svg:not(.down):not(.up) {
  opacity: 1;
}
.articles-header .row > div > span > svg {
  position: absolute;
  right: -15px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .search-tabs {
    font-size: 20px;
  }
  .col-premd-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  /* .articles-header .row > .row > div,
  .article-block .row > .row > div {
    padding: 0 15px;
  } */
  .text-premd-right {
    text-align: right;
  }
}

.on-my-way.pop-block {
  top: 100%;
  right: 0;
  left: unset;
  width: 240px;
}

@media (max-width: 800px) {
  .on-my-way.pop-block {
    display: none;
  }
  .reviews-pop {
    display: none !important;
  }
}

@media screen and (min-width: 959px) {
  .toogle-burger {
    display: none;
  }
}

@media screen and (max-width: 959px) {
  .header-sign-up {
    padding-right: 20px !important;
  }
}

.MuiInputBase-input {
  padding: 0 !important;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
  border: 0 !important;
}

@media screen and (max-width: 380px) {
  .col-min380-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 380px) and (max-width: 576px) {
  .col-576px-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 479px) {
  .col-480-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 479px) {
  .header-sign-up {
    display: none;
  }
  .col-380-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .text-380-left {
    text-align: left;
  }
  .text-380-center {
    text-align: center;
  }
  .col-380-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .d-380 {
    display: none;
  }
}

.more-click {
  align-self: center;
  margin-top: 5px;
}

.article-block .row > .row > div {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 773px) {
  .article-block .row > .row > div {
    padding: 0;
  }
  .text-640-center {
    text-align: center;
  }
  .background-gray-768 {
    background-color: #f7f7f7;
  }
  .d-640-none {
    display: none;
  }
}

.background-gray {
  background-color: #f7f7f7;
}

@media screen and (max-width: 640px) and (min-width: 480px) {
  .col-presm-8 {
    flex: 0 0 66.6666666667% !important;
    max-width: 66.6666666667% !important;
  }
  .col-presm-4 {
    flex: 0 0 33.3333333333% !important;
    max-width: 33.3333333333% !important;
  }
}

/* Media */

/* Elements */

.button-fill {
  color: #171717;
  width: max-content;
  border: 1px solid #e6ce76;
  background-color: #ffdc1c;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
}

.button-fill.submit {
  padding: 10px 35px;
  font-size: 1.4em;
}

.custom-hover {
  overflow: hidden;
}

.button-fill::before,
.custom-hover::before {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  display: block;
  width: 40px;
  height: 40px;
  background: #ffedaf;
  border-radius: 50px;
  z-index: 0;
}

.yellow-after::before {
  background: #ffedaf !important;
}

.button-fill:hover::before,
.custom-hover:hover::before {
  -webkit-transform: scale(8.3);
  transform: scale(8.3);
}

.hover-underline:hover {
  text-decoration: underline;
}

.border-none.button-empty,
.border-none.button-fill {
  border: 0px solid #6c6c6c;
}

.button-empty {
  cursor: pointer;
  color: #171717;
  width: max-content;
  border: 1px solid #6c6c6c;
  background-color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.button-empty::before {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  display: block;
  width: 40px;
  height: 40px;
  background: #f9f8f8;
  border-radius: 50px;
}

.button-empty:hover::before {
  -webkit-transform: scale(8.3);
  transform: scale(8.3);
}

.button-fill a,
.button-empty a {
  text-decoration: none;
  color: #171717;
}

input[type="time"]::-webkit-clear-button,
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="time"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input::-webkit-calendar-picker {
  display: block;
}

/* Firefox */

input[type="number"],
input[type="date"],
input[type="time"] {
  -moz-appearance: textfield;
}

.input-time {
  text-align: center;
}

.input-text,
.input-phone,
.input-time,
.input-date,
.input-number {
  border-radius: 50px;
  height: 42px;
  border: 1px solid #b9b9b9;
  background-color: #fff;
  padding: 0 20px;
  font-size: 16px;
  color: #171717;
  font-family: Roboto;
}

.input-password {
  border-radius: 50px;
  height: 42px;
  border: 1px solid #b9b9b9;
  padding: 0 22px;
  font-size: 16px;
  color: #171717;
}

.input {
  position: relative;
}

.input .toggle-show-password {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 28px;
  top: 0;
  height: 42px;
}

.input .toggle-show-password img {
  margin: auto;
}

.input-error {
  border: 1px solid #f9c8c8;
  margin-bottom: 15px;
}

input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

input[type="checkbox"]:disabled ~ label {
  background-color: #dddddd;
}

input[type="checkbox"]:disabled ~ label ~ span {
  color: #dddddd;
}

input[type="checkbox"] ~ label {
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border: 1px solid #b9b9b9;
  border-radius: 7px;
  background-color: #fff;
}

.input-date {
  max-width: 130px;
}

.fixed-width {
  max-width: 85px !important;
}

.left-angle.angle-go:before {
  width: 9px;
  height: 9px;
  top: 0;
  transform: rotate(140deg);
}

.page-common {
  border-top: 1px solid #6c6c6c;
  padding-top: 35px;
}

.page-common .nomatch-page {
  border-top: none;
}

.articles-block {
  width: 100%;
  position: relative;
}

.moreParams > * {
  padding: 0 15px;
}

input[type="checkbox"]:checked ~ label::after,
.left-angle:before {
  content: "";
  top: -3px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
  width: 7px;
  height: 12px;
  border: solid #9509ef;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.status-passport > p.active {
  filter: contrast(0.1);
}

.status-passport > p:first-child {
  margin-top: 5px;
}

.status-passport > p {
  margin-top: 15px;
  font-size: 12px;
  line-height: 14px;
}

.status-passport > p img {
  margin-right: 7px;
}

.input-error-label {
  position: absolute;
  color: #d36161;
  font-size: 12px;
  bottom: -13px;
  right: 15px;
  padding-left: 7px;
  border-top: 1px solid #f9c8c8;
}

.input-error-label::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 1px;
  transform: rotateZ(45deg);
  border-top: 1px solid #f9c8c8;
  top: -6px;
  left: -12.5px;
}

@media (max-width: 480px) {
  .filter .filter-button {
    max-width: 166px;
  }
}

@media (min-width: 552px) {
  .create-page .carType {
    width: 230px;
  }
}

.create-page .carInfo > div {
  padding: 0 15px;
  margin-bottom: 10px;
}

.create-page .carInfo .carName {
  flex: 1;
  max-width: 100%;
}

@media (max-width: 551px) {
  .create-page .carInfo > div {
    width: 100%;
  }
}

.create-order-page .carPhoto {
  vertical-align: top;
  width: 119px;
  height: 95px;
  background-color: #d9d9d9;
  object-fit: cover;
}

.filter-input-title {
  line-height: 16px;
  padding: 0 5px 0 0;
  font-size: 14px;
}

.filter-open {
  display: inline;
  color: #a038e3;
  cursor: pointer;
}

.filter-actions {
  text-align: right;
  display: inline-block;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px !important;
}

.lh-20 {
  line-height: 20px !important;
}

.mobile.filter-button {
  max-width: 175px;
}

.filter-line > * {
  margin-top: 15px;
  margin-bottom: 0;
}

.filter-line > .moreInfoCheckBox {
  margin-top: 15px;
}

.filter-line .pop-block {
  padding: 5px 10px;
  top: 100%;
  left: 15px;
  color: #919191;
  width: 300px;
  display: none;
}

.filter-close {
  position: absolute;
  top: 0;
  right: 30px;
}

.filter-line .check-list > div {
  margin: 0 30px 15px 0;
}

.budjet_div input {
  max-width: 150px !important;
}

.filter-line h5 {
  font-size: 14px;
  font-weight: normal;
}

@media (max-width: 392px) {
  .colspan-input {
    max-width: 320px;
  }
  .colspan-input input {
    min-width: 50px;
    max-width: 50px;
  }
}

input[placeholder] {
  text-overflow: ellipsis;
}

input::-moz-placeholder {
  text-overflow: ellipsis;
}

input:-moz-placeholder {
  text-overflow: ellipsis;
}

input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

@media (max-width: 740px) {
  .box-grooz-wrapper {
    margin: auto;
  }
}

@media (min-width: 380px) {
  .colspan-input {
    max-width: 390px;
    min-width: 320px;
  }
  .colspan-input input {
    min-width: 80px;
    max-width: 100px;
  }
}

.colspan-input {
  display: flex;
}

.colspan-input .input {
  display: contents !important;
}

.colspan-input input {
  padding: 0 5px;
}

.colspan-input > .input:nth-child(1) input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.colspan-input > .input:nth-child(1):after,
.colspan-input > .input:nth-child(2):after {
  content: "-";
  height: 42px;
  display: flex;
  border: 1px solid #b9b9b9;
  border-left: none;
  border-right: none;
  align-items: center;
}

.colspan-input > .input:nth-child(2) input {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.colspan-input > .input:nth-child(3) input {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.notification-item {
  padding-bottom: 12px;
  font-size: 14px;
}

.notification-item a {
  color: #ad2dff;
}

.notification-item .border-top {
  border-top: 1px solid #dddddd;
  width: 100%;
  margin-bottom: 12px;
}

.notification-item .date,
.notification-item .readble {
  color: #6c6c6c;
}

.notification-item.unreadble .action-read {
  cursor: pointer;
}

.notification-item .text-notification {
  word-break: break-all;
}

.notification-item.unreadble .text-notification {
  font-weight: bold;
}

.notification-item .time {
  margin-left: 11px;
}

.notification-item * {
  display: inline-block;
}

/* Elements end */

/* Header */

.header {
  height: 88px;
}

.header-content {
  height: 100%;
}

.header-content > * {
  height: 100%;
}

.header-logo {
  height: 88px;
}

@media (min-width: 992px) {
  .header-logo {
    padding-right: 40px;
  }
}

@media (max-width: 992px) {
  .header-logo {
    padding-right: 0px;
  }
}

.header-logo-img {
  margin: 0 auto;
  display: block;
  /* width: 158px; */
}

.header-navigation {
  height: 100%;
  font-size: 14px;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.header-navigation nav ul {
  overflow: hidden;
}

.header-navigation nav {
  display: flex;
  height: 100%;
}

.header-navigation .moreMenu {
  display: inline-block;
}

.header-navigation ul {
  margin: 0;
  padding: 0;
  height: 100%;
}

.notTariff svg {
  margin-bottom: 5px;
}

.notTariff * {
  display: block;
  margin: auto;
}

.notTariff {
  text-align: center;
}

.header-navigation ul li {
  display: inline-block;
  height: 100%;
}

.header-navigation ul li a {
  display: inline-block;
  color: #322f2f;
  padding: 34px 8px;
  height: 100%;
  transition: 0.2s;
  font-size: 14px;
}

header ~ div {
  min-height: 80vh;
}

.register span {
  position: relative;
  z-index: 1;
}

.register-form .row > div {
  padding-left: 10px;
  padding-right: 10px;
}

.register-form > .tabs {
  margin-bottom: 10px;
}

.register-form .tab {
  margin-right: 0;
  margin-left: 28px;
}

.register-form > *:not(.tabs),
.wrapper-animation > *:not(.tabs) {
  margin-bottom: 15px;
  margin-top: 0;
}

.register > span:after {
  /* content: ""; */
  padding: 19px 24px;
  z-index: -1;
  height: unset;
  right: 0;
  width: 10px;
  position: absolute;
  background-color: #ffdc1c;
  -moz-transform: translate(9px, 0px) skew(-15deg, 0deg);
  -webkit-transform: translate(9px, 0px) skew(-15deg, 0deg);
  -o-transform: translate(9px, 0px) skew(-15deg, 0deg);
  -ms-transform: translate(9px, 0px) skew(-15deg, 0deg);
  transform: translate(9px, 0px) skew(-15deg, 0deg);
}

.login span {
  position: relative;
  z-index: 1;
}

.login > span:after {
  /* content: ""; */
  padding: 18px 24px;
  z-index: -1;
  left: -13px;
  width: 5px;
  position: absolute;
  border: 1px solid #6c6c6c;
  border-right: 0;
  background-color: #fff;
  -moz-transform: translate(9px, 0px) skew(-15deg, 0deg);
  -webkit-transform: translate(9px, 0px) skew(-15deg, 0deg);
  -o-transform: translate(9px, 0px) skew(-15deg, 0deg);
  -ms-transform: translate(9px, 0px) skew(-15deg, 0deg);
  transform: translate(9px, 0px) skew(-15deg, 0deg);
}

.toogle-burger {
  padding: 35px 0;
}

.header-navigation ul li a:hover {
  color: #ad2dff;
}

.footer_list ul li a:hover {
  text-decoration: underline;
}

.header-navigation ul li a.active {
  color: #ad2dff;
}

.header-feedback {
  width: 200px;
  padding: 25px 0;
  cursor: pointer;
}

.header-feedback-number {
  display: block;
  color: #3f3c3c;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
}

.header-feedback-label {
  display: block;
  color: #a038e3;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
}

.header-sign-up {
  padding: 23px 0;
}

.toogle-burger .bm-item a {
  width: 100%;
  text-align: left !important;
  padding: 7px 0;
  transition: all 0.2s;
  display: block;
  color: #fff;
}

.toogle-burger .seperator {
  width: 100%;
  height: 1px;
  background-color: #737373;
}

.toogle-burger .bm-item a.active {
  color: #ffdc1c !important;
}

.header-profile {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.tariff-pop a span {
  line-height: 14px;
  display: block;
  color: #171717;
}

.tariff-pop .pop-block {
  font-size: 12px;
  padding: 21px 18px;
  width: 169px;
}

.message-nav img {
  min-width: 15px;
}

.tariff-pop .pop-block p {
  text-align: left;
}

@media (max-width: 585px) {
  .tariff-pop,
  .header-fast-access {
    display: none !important;
  }
}

.tariff-pop .pop-block {
  right: 0;
  left: unset;
}

.tariff-pop .pop-block::before,
.tariff-pop .pop-block:after {
  right: 11px;
  left: unset;
}

.pop-block.notAngle::before,
.pop-block.notAngle:after {
  display: none;
}

.header-profile-name {
  text-align: right;
  color: #171717;
}

.header-profile-name p {
  line-height: 14px;
}

.star-icon {
  cursor: pointer;
}

.review-item {
  padding: 18px 22px;
  overflow: hidden;
}

.review-item textarea {
  width: 100%;
}

textarea {
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 12px;
  resize: none;
  padding: 10px;
}

.pop-block.left {
  width: 240px;
  left: -11px;
  top: 25px;
}

.pop-block.bottom {
  width: 385px;
  top: 100%;
}

.pop-wrapper {
  position: relative;
}

.pop-wrapper .pop-block {
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.tariff-pop .pop-block,
.fast-access-btn .pop-block,
.pop-wrapper:hover .pop-block {
  opacity: 1;
  pointer-events: all;
  max-width: 3500px;
  max-height: 3500px;
}

.pop-block {
  width: 216px;
  left: 0;
  top: 85px;
  /* max-width: 0;
  max-height: 0; */
}

.profile-menu {
  width: max-content;
  right: -9px;
  top: 85px;
}

.profile-input-fixed > div {
  max-width: 310px;
}

@media (min-width: 992px) {
  .foto-lable-passport {
    width: 140px;
    vertical-align: center;
  }
}

.defaultPassport {
  width: 100px;
  height: 70px;
}

.adress-input .row > * {
  margin-bottom: 10px;
}

.adress-input .row > span {
  display: inline-block;
  min-width: 80px;
}

.sharected-link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.profile-menu,
.pop-block {
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  position: absolute;
  background-color: #fff;
  border: 1px solid #eee;
  z-index: 3;
  border-radius: 10px;
  margin: auto;
}

.profile-menu::after {
  content: "";
  position: absolute;
  top: -16px;
  right: 16px;
  border: 8px solid transparent;
  border-bottom: 9px solid #fff;
}

.pop-block:after {
  content: "";
  position: absolute;
  top: -16px;
  left: 11px;
  border: 8px solid transparent;
  border-bottom: 9px solid #fff;
}

.profile-menu::before {
  content: "";
  position: absolute;
  top: -18px;
  right: 16px;
  border: 8px solid transparent;
  border-bottom: 9px solid #eee;
}

.pop-block::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 11px;
  border: 8px solid transparent;
  border-bottom: 9px solid #eee;
}

.profile-menu-item {
  padding-left: 44px !important;
  max-width: 200px;
}

.rating-head {
  display: inline-flex;
  margin-right: 10px;
  align-items: flex-start;
}

.rating-head img {
  margin-left: 4px;
  vertical-align: auto;
}

.pop-block-item {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pop-block-item:not(.nohref):hover {
  color: #9509ef;
}

.profile-menu-item,
.pop-block-item {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 11px 14px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 999;
}

.pop-block-item-simple {
  position: relative;
  padding: 19px 14px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 999;
}

.profile-menu a,
.pop-block a {
  color: #171717;
}

.profile-menu-item img {
  display: inline-block;
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.profile-menu-additionally,
.pop-block-additionally {
  width: 80px;
  height: 30px;
  border-radius: 50px;
  background-color: #f7f7f7;
  color: #171717;
  margin: 10px auto;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  padding: 7px 0;
}

.profile-menu a:hover {
  color: #9509ef;
}

.profile-menu-item:hover img {
  filter: invert(22%) sepia(99%) saturate(7342%) hue-rotate(277deg)
    brightness(93%) contrast(111%);
}

.header-profile-arrow-down img {
  display: block;
  margin: 3px 8px;
}

.header-additionals {
  padding: 23px 0;
  padding-right: 10px;
}

.order-link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-step {
  margin-top: 20px;
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.slide-step > * {
  margin: 0 10px;
  margin-top: 10px;
}

.more-menu {
  cursor: pointer;
  padding: 34px 10px;
  display: inline-block;
  white-space: nowrap;
  /* display: none; */
}

.more-menu img {
  vertical-align: middle;
}

.more-menu:hover .submenu {
  display: block;
}

.lk-order-page {
  padding-bottom: 40px;
}
.lk-order-page .articles-block {
  min-height: 73.5vh;
}
.more-menu .submenu {
  position: absolute;
  display: none;
  z-index: 2;
  width: max-content;
  background-color: #f9f8f8;
  padding: 19px 14px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
}

.more-menu .submenu li {
  display: block;
}

.more-menu .submenu li a {
  padding: 5px 10px;
}

@media (max-width: 459px) {
  .header-fast-access {
    display: none;
  }
  .header-content {
    justify-content: space-between;
  }
}

@media (min-width: 459px) {
  .header-fast-access {
    padding: 0 15px 0;
    display: flex;
  }
}

.notifications .pop-block-item:hover a {
  color: #9509ef;
}

.fast-access-btn.notifications.not-empty img {
  filter: invert(10%) sepia(99%) saturate(1250%) hue-rotate(338deg)
    brightness(106%) contrast(103%);
}

.fast-access-btn.messages:hover svg > path:first-child,
.fast-access-btn.messages.not-empty svg > path:first-child {
  fill: #eed4ff;
}

.fast-access-btn.messages:hover svg > path:not(:first-child),
.fast-access-btn.messages.not-empty svg > path:not(:first-child) {
  fill: #9509ef;
}

.fast-access-btn.notifications:hover img,
.fast-access-btn.notifications.not-empty:hover img {
  filter: invert(10%) sepia(99%) saturate(1250%) hue-rotate(338deg)
    brightness(106%) contrast(103%) drop-shadow(0 4px 9px rgba(0, 0, 0, 0.1));
}

.fast-access-btn.messages.not-empty:hover svg,
.fast-access-btn.messages:hover svg {
  filter: drop-shadow(0 4px 9px rgba(0, 0, 0, 0.1));
}

.header-fast-access .fast-access-btn {
  display: flex;
  padding: 0 10px;
  transition: box-shadow 0.2s;
  cursor: pointer;
  background-color: none;
  align-items: center;
  position: relative;
}

.header-fast-access .fast-access-btn .action-counter {
  top: 24px;
  right: 0px;
}

/* Header end */

/* Footer */

.footer {
  background: #f7f7f7;
  font-size: 12px;
}

.social_link hover {
  filter: invert(98%) sepia(42%) saturate(0%) hue-rotate(312deg)
    brightness(120%) contrast(98%);
}

.social_link img {
  margin: 10px 7px;
}

.footer_list > div {
  min-width: 50%;
}

.footer_list ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.footer_list .title-ul {
  font-weight: 500;
}

.footer_list ul a:not(.mail) {
  color: #6c6c6c;
}

.footer_list a:not(.mail) {
  text-decoration: none;
  color: #000;
}

.footer_list p {
  margin-bottom: 12px;
}

.footer_list li {
  margin-bottom: 5px;
}

a.mail {
  color: #a038e3;
}

.postscript {
  color: #6c6c6c;
  font-size: 12px;
}

.download_link img {
  width: 107px;
  margin-top: 10px;
}

/* footer end */

/* Main page */

.main-preview {
  background-color: #f9f8f8;
  height: max-content;
  padding: 50px 0;
  margin: 0 !important;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.main-preview .container-fluid {
  width: 100%;
}

.main-title {
  margin: 0;
  margin-top: 70px;
}

.main-subtitle {
  font-size: 36px;
  color: #171717;
  font-weight: normal;
  margin: 0;
  line-height: 24px;
}

.list_href a {
  white-space: nowrap;
}

.list_href a:after {
  content: "»";
  margin-left: 3px;
}

.main-description-title {
  font-size: 24px;
  font-weight: normal;
  margin: 0;
}

.main-description-subtitle {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.main-preview-register {
  margin-top: 30px;
}

.button-fill,
.button-empty {
  line-height: 18px;
}

.main-preview-register > a > span {
  width: 200px;
}

.MuiPickersClockPointer-thumb {
  border-color: #ffdc1c !important;
  background-color: #fff !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersClockPointer-pointer,
.MuiPickersDay-daySelected {
  background-color: #ffdc1c !important;
}

@media (max-width: 991px) {
  .filter-close {
    display: block;
  }
  .filter-open {
    display: none;
  }
}

.filter .settingsSvg {
  width: 28px;
  height: 28px;
  fill: #000;
  cursor: pointer;
}

.filter .settingsSvg:hover,
.filter .settingsSvg.active {
  fill: #ad2dff;
}

@media (max-width: 767px) {
  .main-preview-register > a > span > span,
  .main-description-title {
    font-size: 14px;
  }
  .main-preview-register > a > span {
    width: 155px;
  }
}

@media (max-width: 639px) {
  .main-preview-register > a > span {
    width: 175px;
  }
  .main-image-block {
    display: none;
  }
  .main-text-block {
    text-align: center;
  }
  .main-description-title {
    margin-top: 13px;
  }
  .main-advantage-label {
    display: none;
  }
}

.search-tabs a {
  color: inherit;
}

@media (min-width: 481px) {
  .search-tabs {
    padding-top: 50px;
  }
  .main-advantage-title {
    font-size: 20px;
  }
  .main-advantage-title img {
    width: 22px;
    vertical-align: middle;
  }
}

@media (max-width: 479px) {
  .search-tabs {
    padding-top: 16px;
    font-size: 14px;
  }
  .main-description-subtitle {
    display: none;
  }
  .main-preview-register {
    text-align: center;
  }
  .main-advantage-title {
    font-size: 14px;
  }
  .main-advantage-title img {
    width: 21px;
    vertical-align: middle;
  }
  .main-preview-register > a {
    display: block;
    width: max-content;
    margin: 0 auto;
  }
  .main-preview-register > a:last-child {
    margin-top: 14px;
  }
}

@media (min-width: 640px) {
  .main-description-title {
    margin-top: 35px;
  }
  .main-text-block {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .main-image-block {
    align-items: center;
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .header-sign-up {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .mb-custom {
    margin-bottom: 19px;
  }
  .main-image-block {
    display: none;
  }
  .main-preview {
    text-align: center;
  }
  .main-description-title {
    margin-top: 16px;
  }
}

@media screen and (max-width: 401px) {
  .main-preview-register span {
    margin: 0 !important;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .main-description-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 991px) {
  .footer-logo-img {
    width: 100%;
  }
  .pl-custom {
    padding-left: 80px !important;
  }
  .main-subtitle {
    font-size: 24px;
  }
  .main-preview {
    padding: 0 0px 50px 0px;
  }
  .main-preview .row.col-12 {
    margin: 0 !important;
  }
}

.main-previewimage {
  margin: 0 auto;
  display: block;
  margin-top: 40px;
  width: 100%;
  max-width: 470px;
  align-self: center;
}

.search-button {
  max-width: 220px !important;
}

.main-advantage {
  margin-top: 20px;
}

.main-advantage-title {
  white-space: nowrap;
}

.main-advantage-title {
  margin: 0;
  margin-bottom: 5px;
  font-weight: normal;
  color: #171717;
}

.main-advantage-label {
  line-height: 18px;
  margin: 0;
  font-size: 14px;
  color: #171717;
}

.main-profitably {
  padding: 0 50px !important;
  border: 0;
  padding-bottom: 70px !important;
}

.main-profitably hr {
  border: 0;
  border-top: 1px solid #6c6c6c;
  margin: 0;
}

.main-page hr {
  border: none;
  background-color: #6c6c6c;
  height: 1px;
}

.carrier-page hr,
.cargo-page hr {
  border: none;
  background-color: #b9b9b9;
  height: 1px;
  border-color: transparent;
}

.main-profitably .row {
  justify-content: space-between;
}

.main-profitably h3 {
  font-weight: normal;
  margin-top: 47px;
  font-size: 24px;
  text-align: center;
}

.main-profitably-block {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  margin-top: 25px;
  max-width: 465px;
}

.main-profitably h5 {
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  margin-bottom: 15px;
  color: #171717;
  position: relative;
  padding-left: 40px;
}

.main-profitably-image {
  position: absolute;
  left: 0;
  top: 1px;
}

.main-profitably p {
  color: #6c6c6c;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 40px;
}

.main-questions {
  background-color: #f9f8f8;
  position: relative;
  padding: 0 35px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.main-questions h3 {
  color: #171717;
  text-align: center;
  margin: 50px 0;
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-weight: normal;
}

/* Main slider */

.parent {
  width: 85%;
  overflow: hidden;
  margin: auto;
  padding: 1rem;
}

.slider-container {
  /* if you want to have a scrollbar, you can add overflow here */
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
}

.main-questions .slider-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 250px;
}

.btn-block {
  position: absolute;
  cursor: pointer;
  width: 42px;
  height: 20px;
  text-align: center;
  top: 25px;
  right: 30px;
}

.btn-block .btn-prev {
  display: inline-block;
  margin-right: 10px;
}

.btn-block .btn-next {
  display: inline-block;
  margin-left: 10px;
}

.prev {
  left: 1rem;
}

.next {
  right: 1rem;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

.child-questions {
  flex: 0 0 25%;
  padding: 0 50px;
  cursor: pointer;
  position: relative;
  height: 100%;
}

.child-questions,
.child-questions * {
  transition: 0.2s;
}

.child-question {
  background: #ffffff;
  border-radius: 22px;
  padding: 0 40px;
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  box-shadow: 4px 4px 0px 0px #dddddd;
}

.child-question-text {
  opacity: 0;
  position: absolute;
  transition: opacity 0s;
}

.child-questions:hover .child-question {
  height: 240px;
  padding: 0 20px;
}

.child-questions:hover .child-question-text {
  display: inline;
  position: relative;
  text-align: left;
  opacity: 1;
  transition: opacity 0.8s;
}

.child-questions:hover .child-question-title {
  display: none;
}

.child-preview-img {
  width: 34px;
  height: 34px;
  border-radius: 50px;
  background-color: #ad2dff;
  position: absolute;
  left: calc(50% - 17px);
  right: 0;
  bottom: -64px;
}

.child-questions:hover .child-preview-img {
  left: calc(100% - 44px);
  right: 5px;
  bottom: 10px;
  background-color: #dddddd;
}

.child-preview-img img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

/* Main slider end */

.main-cities {
  height: max-content;
  position: relative;
  line-height: 16px;
  padding: 40px 50px 40px 50px;
  overflow: hidden;
}

.filter {
  position: relative;
  padding-bottom: 10px;
}

.filter .select * {
  font-size: 16px;
  line-height: 19px;
}

.main-cities ul li {
  text-align: center;
}

.main-cities-item {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.main-cities .main-cities-item a {
  color: #171717;
}

.main-cities-item:nth-child(3) {
  margin-left: auto;
}

@media (min-width: 992px) {
  .filter-close {
    display: none;
  }
  .filter-open {
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .search-tabs {
    font-size: 14px;
    padding-top: 16px;
  }
  .table-wrapper {
    overflow-x: scroll;
    padding-bottom: 10px;
  }
}

.table-wrapper {
  position: relative;
  z-index: 0;
}

@media (min-width: 768px) {
  .main-cities-item {
    flex: 0 0 17%;
    max-width: 17%;
  }
}

@media (min-width: 992px) {
  .avatar-img-wrapper {
    max-width: 150px !important;
    min-width: 150px !important;
  }
}

.link-unhover {
  text-decoration: underline;
  color: #171717;
}

.link-unhover:hover {
  text-decoration: none;
}

@media (min-width: 640px) and (max-width: 773px) {
  .main-cities-item {
    flex: 0 0 23%;
    max-width: 23%;
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  .main-cities-item {
    flex: 0 0 33%;
    max-width: 33%;
  }
  .main-cities-background {
    position: unset !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .main-cities-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .main-cities-background {
    position: unset !important;
  }
}

@media screen and (max-width: 1024px) {
  .main-cities {
    text-align: center;
    padding: 25px 15px 40px 15px !important;
  }
}

@media screen and (max-width: 576px) {
  .main-cities h3 {
    margin-bottom: 24px !important;
  }
  .filter {
    padding: 10px 15px;
    padding-top: 0;
  }
  .standart-page h1 {
    padding: 10px 15px !important;
  }
}

.main-cities h3 {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 80px;
}

.main-cities-background {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  z-index: -1;
}

.main-cities ul {
  padding: 0;
}

.main-cities ul li {
  list-style: none;
  color: #171717;
  font-size: 14px;
  margin-bottom: 14px;
}

.main-cities ul li:hover {
  color: #ad2dff;
}

/* Main end */

/* Articles */

.mobile-row-article > div {
  margin-top: 10px;
}

.moreinfo_block {
  overflow: hidden;
  margin-top: 40px !important;
}
.article-block .car-image {
  height: 80px;
  margin-top: 20px;
  cursor: pointer;
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 992px) {
  .article-block img.moreinfo {
    margin-top: 10px;
    display: block;
    height: 80px;
    width: auto !important;
  }
}

.article-block.hover {
  background-color: #f7f7f7;
}

.article-block img.moreinfo {
  width: 100%;
  object-fit: contain;
}

/*Animation Height */

.height-animation-enter {
  opacity: 0;
  margin-top: 0 !important;
  max-height: 0;
}

.height-animation-enter.height-animation-enter-active {
  opacity: 1;
  margin-top: 40px !important;
  transition: all 0.5s;
  max-height: 300px;
}

.height-animation-leave {
  opacity: 1;
  margin-top: 40px !important;
  max-height: 300px;
}

.height-animation-leave.height-animation-leave-active {
  opacity: 0;
  margin-top: 0 !important;
  max-height: 0;
  transition: all 0.5s;
}

.cookie-animation-item-enter {
  transform: translate(-220px, 0px);
}

.cookie-animation-item-enter.cookie-animation-item-enter-active {
  transform: translate(0, 0px);
  transition: all 0.5s;
}

.cookie-animation-item-leave {
  transform: translate(0, 0px);
}

.cookie-animation-item-leave.cookie-animation-item-leave-active {
  transform: translate(-220px, 0px);
  transition: all 0.5s;
}

/*Animation Height Item*/

.height-animation-item {
  overflow: hidden;
}

.height-animation-item-enter {
  opacity: 0;
  max-height: 0;
}

.height-animation-item-enter.height-animation-item-enter-active {
  opacity: 1;
  transition: all 0.5s;
  max-height: 3000px;
}

.height-animation-item-leave {
  opacity: 1;
  max-height: 3000px;
}

.height-animation-item-leave.height-animation-item-leave-active {
  opacity: 0;
  max-height: 0;
  transition: all 0.5s;
}

.small-loading .LoadGif {
  position: relative !important;
}

.small-loading .LoadGif svg {
  width: 50px;
  height: 48px;
}

.LoadGif {
  z-index: 1;
  pointer-events: none;
}

.LoadGif:not(.fixed) {
  position: absolute;
}

.LoadGif svg {
  width: 150px;
  margin: auto;
}

.LoadGif.fixed {
  position: fixed;
  z-index: 5;
  top: 100px;
  right: 30px;
  width: 50px;
}

.LoadGif.fixed svg {
  width: 50px;
}

.public-page > div:first-child {
  min-height: calc(100vh - 200px);
}

/*Animation Height Item*/

.loading-animation-item-enter {
  opacity: 0;
}

.loading-animation-item-enter.loading-animation-item-enter-active {
  opacity: 1;
  position: absolute;
  transition: all 300ms;
}

.loading-animation-item-leave {
  position: absolute;
  opacity: 1;
  max-height: 300px;
}

.loading-animation-item-leave.loading-animation-item-leave-active {
  opacity: 0;
  max-height: 0;
  transition: all 300ms;
}

/*Animation Height Item*/

.loading-height-animation-item-enter {
  opacity: 0;
}

.loading-height-animation-item-enter.loading-height-animation-item-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.loading-height-animation-item-leave {
  opacity: 1;
}

.loading-height-animation-item-leave.loading-height-animation-item-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}

@media (min-width: 1024px) {
  .search-tabs {
    font-size: 24px;
  }
  /* .articles-header,
  .dialogs-block,
  .article-block {
    padding: 0 50px;
  } */
}

.article-block .content .imgs-content {
  margin: 10px -14px;
  display: flex;
  flex-wrap: wrap;
}

.article-block .content .imgs-content div {
  cursor: pointer;
  margin: 0 14px;
  width: 75px;
  height: 75px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.border-yellow {
  border-color: #ffd12c;
}

@media (max-width: 992px) {
  .articles-header {
    display: none;
  }
  .row-input-controls {
    width: auto;
    margin-left: auto;
    padding: 0 15px;
    align-self: end;
  }
  .action-list .pop-block .profile-menu-item {
    display: flex;
    padding-left: 20px !important;
  }
  .action-list .pop-block svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  .action-list .pop-block::before {
    right: 11px;
    left: unset;
  }
  .action-list .pop-block .profile-menu-item:hover,
  .action-list .pop-block .profile-menu-item:hover svg * {
    stroke: #9509ef;
    color: #9509ef;
  }
  .action-list .pop-block::after {
    right: 11px;
    left: unset;
  }
  .action-list {
    position: relative;
  }
  .action-list .pop-block {
    top: 115%;
    left: unset;
    right: -13px;
    width: 169px;
  }
  .action-list .profile-menu-item:last-child {
    border-color: transparent;
  }
}

@media (min-width: 993px) {
  .row-input-controls {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    margin: auto 0;
    text-align: left;
  }
}

.reviews-pop,
.status-label {
  display: inline-flex;
  padding: 0 15px;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
}

.status-label div {
  font-size: 12px;
  line-height: 14px;
}

.status-label.more-width {
  max-width: 220px;
}

.status-label {
  max-width: 320px;
  text-align: left;
}

.status-label > * {
  display: inline-block;
}

@media (max-width: 380px) {
  .row-parametrs {
    flex-wrap: wrap;
  }
  .row-parametrs > * {
    margin-top: 15px;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.articles-block .input-action {
  margin: 10px 5px;
  margin-bottom: 0;
}

.input-action span,
.articles-block .input-action span {
  font-size: 14px;
  line-height: 16px;
  word-break: break-all;
}

.articles-header,
.request-header {
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
  padding-top: 12px;
  padding-bottom: 13px;
  line-height: 23px;
}

.articles-header div {
  font-size: 18px;
  color: #909090;
}

@media (max-width: 960px) {
  .articles-header div {
    font-size: 14px;
  }
  .articles-header {
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 16px;
  }
}

.article-block .moreinfo_block .fio {
  max-width: 240px;
  word-break: break-all;
}

@media (max-width: 575px) {
  .article-block .moreinfo_block .fio {
    background-color: #f4f4f4;
    padding: 5px 0;
  }
}

.article-block .moreinfo_block .fio a {
  white-space: pre-line;
  color: #000;
}

.article-block .moreinfo_block .fio a:hover {
  color: #9509ef;
  transition: all 0.2s;
}

.article-block .property-user img {
  vertical-align: middle;
}

.article-block .property-user {
  display: block;
  margin-top: 5px;
}

.property-user {
  display: flex;
  align-items: center;
}

.property-user img {
  margin-right: 5px;
  vertical-align: sub;
}

.article-block .title-column {
  color: #919191;
  font-size: 14px;
  line-height: 15px;
  font-weight: normal;
  margin-bottom: 5px;
}

.left-angle {
  margin: 0 20px;
  position: relative;
}

.left-angle:before {
  right: unset !important;
  left: -16px !important;
}

.get-article {
  min-width: 116px;
  line-height: 15px;
}

.article-block {
  border-top: 1px solid #ddd;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 16px;
}

.dialogs-block {
  border-top: 1px solid #ddd;
}

.article-block .moreinfo {
  margin-top: auto;
  overflow: hidden;
  position: relative;
}

.article-block span {
  color: #171717;
  font-size: 14px;
}

@media (min-width: 769px) {
  .more-click.in-row-manage {
    display: block;
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }
}

.article-actions {
  text-align: right;
}

.ID-col {
  width: 8%;
  padding: 0 15px;
}

.car-col,
.FromL-col,
.ToLoc-col,
.Grooz-col,
.Date-col,
.Price-col,
.More-col {
  width: 13.14%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

/* Articles end */

/* FAQ */

.lk-page .faq-page {
  border-top: none;
}

.lk-page .nomatch-page {
  border-top: none;
}

.faq-page {
  border-top: 1px solid #6c6c6c;
  margin-bottom: 50px;
}

.faq-title {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 28px;
  font-weight: normal;
}

.faq-question {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: left;
}

.faq-question h4 {
  margin: 0;
  margin-bottom: 10px;
  min-height: 25px;
  font-size: 20px;
  font-weight: normal;
}

.faq-question ul {
  margin: 0;
  padding: 0;
  line-height: 19px;
}

.faq-question ul li {
  list-style: none;
}

.faq-question ul li a {
  font-size: 16px;
  color: #9509ef;
}

/* FAQ end */

/* Login page */

.login-page {
  border-top: 1px solid #6c6c6c;
  min-height: 483px;
}

.login-title {
  font-size: 24px;
  color: #171717;
  font-weight: normal;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 35px;
}

@media (max-width: 1412px) and (min-width: 1200px) {
  .login-form {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
}

.login-form > * {
  margin-top: 0;
  margin-bottom: 20px;
}

.login-form .bottom {
  border-bottom: 1px solid #dddddd;
}

.input-login {
  width: 420px;
  display: block;
  height: 40px;
  border: 1px solid #b9b9b9;
  border-radius: 50px;
  font-size: 16px;
  padding: 0 20px;
  margin-bottom: 22px;
}

/* Login end */

/* Register page */

.register-page {
  border-top: 1px solid #6c6c6c;
}

.tabs .tab.active,
.tabs .tab.active * {
  text-decoration: underline;
  color: #000;
}

.tabs .tab {
  color: #909090;
  cursor: pointer;
}

.register-title {
  font-size: 24px;
  color: #171717;
  font-weight: normal;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 35px;
}

.simple_select_city {
  padding: 8px 35px;
  background-color: #f9f8f8;
  border-radius: 20px;
  border: 1px solid #eeeeee;
}

.input-register {
  width: 420px;
  display: block;
  height: 40px;
  border: 1px solid #b9b9b9;
  border-radius: 50px;
  font-size: 16px;
  padding: 0 20px;
  margin-bottom: 22px;
}

.simple_select_city {
  cursor: pointer;
  position: relative;
  color: #909090;
}

.simple_select_city.active {
  color: #171717;
}

.simple_select_city.active::before {
  content: "";
  top: -3px;
  left: 15px;
  bottom: 0;
  margin: auto;
  position: absolute;
  width: 7px;
  height: 10px;
  border: solid #9509ef;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

a.href,
a.href span {
  color: #9509ef;
}

a.href.hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* Register end */

/* About */

.about-page {
  border-top: 1px solid #6c6c6c;
  padding: 50px 0;
}

.about-page h1 {
  margin-top: 0;
  font-size: 24px;
  color: #171717;
  font-weight: normal;
}

.about-page ul {
  margin: 0;
  padding: 0;
  margin-bottom: 45px;
}

.about-page ul.head-list {
  margin-bottom: 40px;
}

.about-page ul.head-list li {
  list-style: none;
  line-height: 20px;
}

.about-page ul.head-list li a {
  font-size: 16px;
  color: #9509ef;
}

.about-page h2 {
  font-size: 20px;
  color: #171717;
  font-weight: normal;
  margin: 0;
  margin-bottom: 10px;
}

.about-page h3 {
  font-size: 14px;
  color: #171717;
  font-weight: normal;
  margin: 0;
  margin-bottom: 10px;
}

.about-page h4 {
  font-size: 14px;
  color: #171717;
  font-weight: normal;
  margin: 0;
}

.about-page p,
.about-page ul {
  font-size: 14px;
  color: #6c6c6c;
  font-weight: normal;
  margin: 0;
}

.about-page p span {
  color: #171717;
}

.profile-page hr {
  border: none;
  border-top: 1px solid #dddddd;
  height: 0;
}

.review-article {
  overflow: hidden;
  width: 100%;
}

.profile-page .passport-status {
  display: inline-flex;
}

.profile-page > .row > div {
  margin: 15px 0;
}

.about-page hr {
  border: none;
  border-top: 1px solid #dddddd;
  height: 0;
  margin: 30px 0;
}

/* About end */

/* Cargo */

.cargo-page {
  border-top: 1px solid #6c6c6c;
}

.cargo-page .faq-questions {
  padding-bottom: 50px;
}

.cargo-pros > .row > div,
.carrier-pros > .row > div {
  margin-top: 10px;
}

.cargo-pros {
  padding: 30px 0;
  background-color: #f9f8f8;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin: 0 !important;
}

.cargo-pros-image-block {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border: 2px solid #322f2f;
  border-radius: 50px;
  position: relative;
}

.cargo-pros-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.cargo-pros-label {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  margin-top: 20px;
}

.cargo-steps h3 {
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  padding-top: 45px;
}

.cargo-steps-block {
  margin: 35px 0;
  justify-content: center;
}

.cargo-step {
  background-color: #f7f7f7;
  height: 60px;
  background-clip: content-box;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 0 !important;
  margin: 0 30px 0 15px;
  display: flex;
  align-items: center;
  min-width: 200px;
  max-width: 220px !important;
  position: relative;
  margin-bottom: 15px;
}

.cargo-step::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 40px solid #f7f7f7;
  position: absolute;
  top: 0;
  right: -40px;
}

.cargo-step-number {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  position: absolute;
  border: 1px solid #dddddd;
  box-shadow: 10px 3px 10px -14px #000;
  display: inline-block;
}

.cargo-step-number span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: max-content;
  height: max-content;
  font-size: 36px;
  color: #b9b9b9;
  font-weight: 900;
}

.cargo-step-label {
  padding: 0 0px 0 75px;
  margin: 0;
  height: max-content;
}

/* Cargo end */

/* carrier */

.carrier-page,
.tariffs-page,
.nomatch-page,
.search-page,
.application-page,
.user-page {
  border-top: 1px solid #6c6c6c;
}

.search-page {
  min-height: 53vh;
  padding-bottom: 40px;
}

.standart-page h1 {
  font-size: 24px;
  color: #171717;
  font-weight: normal;
  margin: 0;
  padding: 10px 0px;
  margin-top: 40px;
}

.carrier-page .faq-questions {
  padding-bottom: 50px;
}

.carrier-pros {
  padding: 30px 0;
  background-color: #f9f8f8;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin: 0 !important;
}

.carrier-pros-image-block {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border: 2px solid #322f2f;
  border-radius: 50px;
  position: relative;
}

.carrier-pros-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 30px;
}

.carrier-pros-label {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  margin-top: 20px;
}

.yellow-text {
  color: #ffdc1c;
  font-size: 36px;
  font-weight: 900;
  line-height: 1;
}

.w-85px {
  width: 85px;
}

.yellow-text ~ p {
  color: #6c6c6c;
  white-space: nowrap;
}

.carrier-steps h3 {
  margin: 0;
  font-size: 20px;
  padding-top: 45px;
  font-weight: normal;
}

.carrier-steps-block {
  margin: 35px 0;
  justify-content: center;
}

.carrier-step {
  background-color: #f7f7f7;
  height: 60px;
  background-clip: content-box;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 0 !important;
  margin: 0 25px 0 22px;
  display: flex;
  align-items: center;
  min-width: 180;
  max-width: 190px !important;
  position: relative;
  margin-bottom: 15px;
}

.carrier-step::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 40px solid #f7f7f7;
  position: absolute;
  top: 0;
  right: -40px;
}

.carrier-step-number {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  position: absolute;
  border: 1px solid #dddddd;
  box-shadow: 10px 3px 10px -14px #000;
  display: inline-block;
}

.carrier-step-number span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: max-content;
  height: max-content;
  font-size: 36px;
  color: #b9b9b9;
  font-weight: 900;
}

.carrier-step-label {
  padding: 0 0px 0 75px;
  margin: 0;
  line-height: 16px;
  height: max-content;
}

/* carrier end */

/* SideNav start */

@media (min-width: 1025px) {
  .side-nav {
    min-width: 230px;
  }
  .content.col {
    padding-left: 230px;
    padding-right: 0;
  }
  .side-nav .side-nav-item span {
    padding-left: 22px;
  }
}

.side-nav .side-nav-item.yellow > * {
  z-index: 2;
}

.side-nav .side-nav-item.yellow {
  background-color: #ffdc1c;
}

.content {
  overflow: hidden;
}

@media (max-width: 1024px) {
  .side-nav:not(.open) .parent-item .side-nav-item:not(.label) {
    padding-left: 16px;
  }
  .side-nav {
    min-width: 50px;
  }
  .side-nav.open {
    min-width: 230px;
  }
  .content.col {
    padding-right: 0 !important;
    padding-left: 50px !important;
  }
  .side-nav:not(.open) .side-nav-item > span {
    display: none;
  }
  .side-nav:not(.open) .side-nav-item {
    padding: 15px 16px;
    text-align: center;
  }
  .side-nav:not(.open) .side-nav-item img {
    position: relative;
    left: 0;
    margin: auto;
  }
  .side-nav.open .side-nav-item img {
    margin-right: 22px;
  }
  .side-nav .toogle-sideBar {
    position: absolute;
    cursor: pointer;
    top: 100px;
    right: -16px;
    height: 61px;
    width: 16px;
  }
  .side-nav .toogle-sideBar img.angle {
    position: absolute;
    top: -4px;
    bottom: 0;
    right: 5px;
    margin: auto;
    transform: rotate(180deg);
  }
  .side-nav.open .toogle-sideBar img.angle {
    transform: rotate(0);
  }
}

@media (min-width: 769px) {
  .profile-page hr {
    visibility: hidden;
  }
}

.profile-page hr {
  width: 100%;
}

.profile-page {
  padding-bottom: 40px !important;
}

@media (max-width: 800px) and (min-width: 769px) {
  .content {
    padding-right: 0 !important;
    padding-left: 10px !important;
  }
}

@media (max-width: 800px) {
  .side-nav {
    min-width: 10px;
  }
  .side-nav.open {
    min-width: 230px;
  }
  .content.col {
    padding-right: 0 !important;
    padding-left: 10px !important;
  }
  .side-nav:not(.open) .side-nav-item > img {
    display: none;
  }
  .side-nav:not(.open) .parent-item .side-nav-item:not(.label) {
    padding: 15px 5px !important;
  }
  .side-nav:not(.open) .side-nav-item {
    padding: 15px 5px;
    text-align: center;
  }
}

.side-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 3;
  background-color: #4f4f4f;
  padding: 0 !important;
  transition: min-width 0.1s;
}

.side-nav .parent-item .side-nav-item:not(.label) {
  padding-left: 35px;
}

.side-nav .children-list a.active {
  background-color: #282727;
}

.side-nav .children-list a {
  background-color: #322f2f;
}

.side-nav-item {
  position: relative;
  width: 100%;
  padding: 15px 22px;
  padding-right: 16px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  z-index: 0;
}

.side-nav a,
.side-nav span {
  color: #fff;
  display: block;
  transition: all 0.2s;
  font-size: 12px;
}

.side-nav a:hover,
.parent-item:hover {
  background-color: #6c6c6c;
}

.side-nav .active {
  background-color: #6c6c6c;
}

.side-nav-item.yellow span {
  color: #000;
  font-weight: 500;
}

.side-nav-item span {
  max-width: 168px;
}

.side-nav-item:not(.yellow):not(.download-order) img {
  transition: all 0.2s;
  filter: invert(90%) sepia(32%) saturate(2%) hue-rotate(213deg)
    brightness(108%) contrast(100%);
  -webkit-filter: invert(90%) sepia(32%) saturate(2%) hue-rotate(213deg)
    brightness(108%) contrast(100%);
  -moz-filter: invert(90%) sepia(32%) saturate(2%) hue-rotate(213deg)
    brightness(108%) contrast(100%);
  -o-filter: invert(90%) sepia(32%) saturate(2%) hue-rotate(213deg)
    brightness(108%) contrast(100%);
  -ms-filter: invert(90%) sepia(32%) saturate(2%) hue-rotate(213deg)
    brightness(108%) contrast(100%);
}

/* SideNav end */

/* Lk start */

.lk-page {
  position: relative;
  border-top: 1px solid #dddddd;
  min-height: 55.5vh;
}

.side-nav ~ .content {
  min-height: calc(100vh - 88px);
}

.lk-page .title {
  color: #171717;
  font-size: 20px;
  font-weight: normal;
  margin-top: 17px;
  margin-bottom: 26px;
}

.lk-page ~ footer > .row > div:first-child {
  display: none !important;
}

.lk-page .subtitle {
  color: #171717;
  font-size: 16px;
  padding: 22px 0;
  margin: 0;
  font-weight: normal;
}

.avatar-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  vertical-align: middle;
}

/* Lk end */

/* Notifications */

.tab_groups {
  margin-left: -8px;
  margin-right: -8px;
}

.tab_groups .counter {
  margin-left: 3px;
}

.tab_group .article-block {
  position: relative;
}

.readble_col {
  margin-left: auto;
}

.tab_groups .tab_group.active {
  background-color: #ffdc1c !important;
  transition: all 0.3s;
}

.tab_groups .tab_group {
  position: relative;
}

.tab_groups .tab_group:not(.active):not(.goPrev):hover {
  background-color: #f7f7f7;
  transition: all 0.3s;
}

@media (max-width: 576px) {
  .tab_groups .tab_group {
    display: block;
  }
  .readble_col {
    margin-top: 20px;
  }
}

@media (min-width: 577px) {
  .tab_groups .tab_group {
    display: inline-block;
  }
}

.tab_groups .tab_group.goPrev {
  background: transparent;
  font-size: 14px;
  padding: 10px 20px;
}

.download-order {
  opacity: 0.6;
}

.tab_groups .tab_group:not(.goPrev) {
  background: #eeeeee;
  border-radius: 20px;
  font-size: 12px;
  line-height: 13px;
  cursor: pointer;
  padding: 10px 20px;
  min-width: 90px;
  text-align: center;
  margin: 0 8px 15px 8px;
  color: #000;
}

/* End Notifications */

/* NotificationSettings */

.user-settings > * {
  margin-bottom: 10px;
}

.status-area {
  align-self: center;
}

@media (max-width: 1240px) and (min-width: 871px) {
  .user-settings .filed-label {
    width: 195px;
  }
}

@media (max-width: 870px) and (min-width: 434px) {
  .user-settings .filed-label {
    width: 150px;
  }
}

@media (min-width: 1241px) {
  .user-settings .filed-label {
    width: 235px;
  }
}

@media (min-width: 1412px) {
  .user-settings .filed-label {
    width: 260px;
  }
}

@media (max-width: 433px) {
  .user-settings .filed-label {
    width: 100%;
  }
}

.user-settings .filed-label {
  font-size: 14px;
  line-height: 16px;
  padding: 0 15px;
  min-height: 32px;
}

.reviews-modal {
  width: 100%;
  max-width: 320px;
}

.ReactModal__Content .close-svg {
  position: absolute;
  right: -11px;
  top: -12px;
  cursor: pointer;
  z-index: 1000;
}

.user-settings .gray {
  color: #6c6c6c;
}

.bg-gray {
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
}

@media (max-width: 1219px) {
  .user-settings .fields-checkbox > * {
    white-space: nowrap;
  }
}

.user-settings .fields-checkbox > * {
  display: inline-flex;
  padding: 0 15px;
}

.common-settings {
  border-top: 1px solid #dddddd;
}

.common-settings .setting-title {
  margin-top: 20px;
  margin-bottom: 15px;
}

.setting-title {
  font-size: 16px;
  line-height: 19px;
  height: 19px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 21px;
}

.float-right {
  float: right;
}

/* End NotificationSettings */

/* Paginations */

.pagination {
  list-style: none;
  font-size: 14px;
  display: flex;
  padding: 0;
  line-height: 16px;
  justify-content: flex-end;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .pagination {
    justify-content: center;
  }
  .status-area {
    width: calc(100% - 115px);
  }
}

.pagination li a {
  padding: 11px;
  display: block;
}

.pagination li {
  display: inline-block;
  color: #6c6c6c;
}

.pagination li:not(.disabled):not(.active) {
  cursor: pointer;
}

.pagination li:not(.previous):not(.next) {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.pagination li.active {
  color: #171717;
  font-weight: bold;
}

.pagination li.previous,
.pagination li.next {
  border: 1px solid #dddddd;
}

.pagination li.previous a,
.pagination li.next a {
  align-items: center;
  display: inline-flex;
  height: 100%;
}

.pagination li.previous {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.pagination li.next {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* Paginations */

/* TARIFS */

.tariff {
  border-radius: 13px;
  border: 1px solid transparent;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  padding: 18px 17px;
}

.tariff.yellow {
  background: #ffdc1c;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
}

.tariff .name {
  font-size: 12px;
  line-height: 13px;
  color: #919191;
}

.tariff .price {
  display: block;
  margin-top: 12px;
  font-size: 20px;
  line-height: 23px;
  color: #322f2f;
}

.tariff .tags {
  margin-top: 18px;
}

.tariff .info {
  height: 159px;
}

.tariff .tags span {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #322f2f;
  white-space: break-spaces;
}

.tariff .left-angle:before,
.left-angle.yellow:before {
  border-color: #ffdc1c;
}

.left-angle.gray:before {
  border-color: #b9b9b9;
}

.tariff.yellow .left-angle:before,
.left-angle.white:before {
  border-color: #ffff;
}

.tariff:not(.yellow):hover {
  border: 1px solid #ffdc1c;
}

.tariff.yellow:hover {
  border: 1px solid #b9b9b9;
}

.tariff .sale {
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  min-height: 14px;
  width: 100%;
  color: #322f2f;
}

.tariff.yellow .name {
  color: #322f2f;
}

.tariff.yellow .withoutSale {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  text-decoration: line-through;
}

.tariff-block {
  margin-top: 15px;
  margin-bottom: 40px;
}

@media (min-width: 321px) {
  .tariff-wrapper {
    width: 208px;
  }
}

@media (max-width: 320px) {
  .tariff-wrapper {
    width: 100%;
  }
  .tariff .price,
  .tariff .sale {
    text-align: center;
  }
  .tariff .tags span {
    width: 70px;
    margin: auto;
  }
}

/* Application Page */

.application-page .row > div,
.nomatch-page .row > div {
  margin: auto;
}

.container-fluid {
  max-width: 1920px;
  padding: 0 28px;
  margin: auto;
}

@media (max-width: 768px) {
  .public-page ~ .header,
  .public-page .container-fluid,
  .page-common ~ .header,
  .page-common .container-fluid {
    padding: 0 20px;
  }
}

@media (min-width: 769px) {
  .public-page ~ .header,
  .public-page .container-fluid,
  .page-common ~ .header,
  .page-common .container-fluid {
    padding: 0 70px;
  }
}

.public-page ~ .header,
.public-page .container-fluid,
.page-common ~ .header,
.page-common .container-fluid {
  max-width: 1500px;
  margin: auto;
}

.settings-notification {
  min-height: 70vh;
}

@media (max-width: 768px) {
  .common-settings {
    border-top: none;
  }
  .settings-notification > .row > div {
    border-top: 1px solid #dddddd;
  }
  .application-page .row > div > div {
    padding: 0 15px;
  }
  .application-page .row {
    text-align: center;
  }
  .application-page .row-3 > div,
  .application-page .row-2 > div {
    display: inline-block;
    font-size: 18px;
  }
  .application-page,
  .nomatch-page {
    padding-bottom: 50px;
  }
}

@media (max-width: 820px) {
  .nomatch-page .row > div {
    display: block;
    text-align: center;
  }
  .nomatch-page .row-3 > div,
  .nomatch-page .row-4 > div {
    display: inline-block;
    font-size: 20px;
  }
}

@media (min-width: 820px) {
  .nomatch-page {
    padding-bottom: 250px;
  }
  .nomatch-page .row > div {
    display: flex;
  }
  .nomatch-page .row > div > .carrier-div {
    padding: 0 13px;
  }
  .nomatch-page .row-3 {
    position: absolute;
    bottom: -50px;
    right: -200px;
    line-height: 23px;
  }
  .nomatch-page .row-3 > div:nth-child(1) {
    font-size: 20px;
  }
  .nomatch-page .row-3 > div:nth-child(2) {
    font-size: 20px;
    margin-left: 30px;
  }
  .nomatch-page .row-3 > div:nth-child(3) {
    font-size: 24px;
    margin-left: 70px;
  }
  .nomatch-page .row-4 {
    position: absolute;
    position: absolute;
    top: 30px;
    left: -250px;
    line-height: 23px;
  }
  .nomatch-page .row-4 > div:nth-child(1) {
    font-size: 20px;
  }
  .nomatch-page .row-4 > div:nth-child(2) {
    margin-left: 100px;
    font-size: 18px;
  }
  .nomatch-page .row-4 > div:nth-child(3) {
    margin-left: 50px;
    font-size: 16px;
  }
  .nomatch-page .row-4 > div:nth-child(4) {
    margin-left: 135px;
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .application-page {
    padding-bottom: 250px;
  }
  .application-page .row > div > div {
    padding: 0 13px;
  }
  .application-page .row-3 > div:nth-child(1) {
    font-size: 20px;
  }
  .application-page .row-3 > div:nth-child(2) {
    margin-left: 50px;
    font-size: 18px;
  }
  .application-page .row-3 > div:nth-child(3) {
    margin-left: 40px;
    font-size: 24px;
  }
  .application-page .row-3 > div:nth-child(1) {
    font-size: 20px;
  }
  .application-page .row-2 > div:nth-child(1) {
    font-size: 20px;
  }
  .application-page .row-2 > div:nth-child(2) {
    font-size: 26px;
  }
}

.application-page h1,
.nomatch-page h1 {
  margin-top: 17px;
  margin-bottom: 40px;
}
.button-empty.grey::before {
  transform: scale(8.3) !important;
}
/*TARIFT PAGE */

.text-decode {
  margin-bottom: 45px;
  cursor: auto;
  width: auto;
}

.tariff .button-empty {
  border: none;
}

.tariffs-list {
  margin-left: -16px;
  margin-right: -16px;
}

.tariffs-list .slider-wrapper {
  width: 100%;
  white-space: nowrap;
  padding: 20px 0;
}

.tariffs-list .slider-wrapper .tariff-wrapper {
  display: inline-block;
  margin: auto 0;
}

@media (min-width: 961px) {
  .tariffs-list .slider-wrapper > div {
    padding: 0 16px;
  }
}

@media (max-width: 960px) {
  .tariffs-list .slider-wrapper > div {
    padding: 0 10px;
  }
}

@media (max-width: 576px) and (min-width: 400px) {
  .col-post-sm-6 {
    flex: 0 0 50% !important;
    max-width: 50%;
  }
}

.tariffs-list .btn-block {
  top: -20px;
  right: 30px;
}

.tariffs-page {
  padding-bottom: 150px !important;
}

.article-page .title {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #171717;
  margin-bottom: 15px;
}

/*Fancybox*/

.slider-next {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100vh - 120px);
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  cursor: pointer;
  margin: 60px 0;
}

.slider-prev {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  height: calc(100vh - 120px);
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  margin: 60px 0;
}

.fancybox-animation-enter {
  opacity: 0;
}

.fancybox-animation-leave.fancybox-animation-leave-active {
  opacity: 0;
  transition: all 0.3s;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 300ms;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 3;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.slider-close {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.slider-prev:hover,
.slider-next:hover,
.slider-close:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.slider-prev svg,
.slider-next svg,
.slider-close svg {
  transition: all 0.2s;
}

.slider-prev:hover svg,
.slider-next:hover svg,
.slider-close:hover svg {
  opacity: 1 !important;
}

.slider-image-container {
  padding: 0 60px;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.slider-image {
  max-width: 100%;
  max-height: 100vh;
  display: flex;
}

@media screen and (max-width: 768px) {
  .slider-next {
    right: 0;
    bottom: 0;
    height: 60px;
    width: 50%;
    margin: 0;
    top: unset;
  }
  .slider-prev {
    left: 0;
    bottom: 0;
    height: 60px;
    width: 50%;
    margin: 0;
    top: unset;
  }
  .slider-close {
    width: 100%;
  }
  .slider-image-container {
    width: 100%;
    padding: 60px 0;
  }
}

.send-input-action {
  min-width: 148px;
}

.review-article,
.request-article,
.dialogs-block .dialog {
  position: relative;
  padding: 20px 28px;
  margin-left: -28px;
  margin-right: -28px;
}

.dialogs-block .dialog > .row {
  align-items: center;
}

.dialogs-block .dialog:hover {
  background-color: #f7f7f7;
}

@media (min-width: 992px) {
  .price-request {
    max-width: 175px !important;
  }
}

.price-request {
  white-space: pre-line;
  font-weight: bold;
}

.review-article > .row,
.request-article > .row {
  justify-content: center;
}

.request-article > .row > div {
  margin-top: 20px;
}

.dialog > .row > div:not(.row) {
  align-items: flex-start;
}

.request-article-block.form {
  padding-top: 20px;
  padding-bottom: 75px;
}

.request-article-block.form .fields {
  margin-top: -15px;
}

@media (min-width: 578px) {
  .request-modal {
    width: 578px;
  }
}

@media (max-width: 578px) {
  .request-modal {
    width: 320px;
  }
}

/* CheckBoxSwitcher*/

.swicher-wrapper > div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.CheckBoxSwitcher-wrapper {
  width: 100%;
  display: flex;
}

.swicher-wrapper .checkboxGroup {
  margin-top: 20px;
}

.swicher-wrapper .checkboxGroup.contract .checkboxParam {
  align-items: center;
}

.swicher-wrapper .checkboxGroup.contract .checkboxParam .react-dadata {
  margin-left: 10px;
}

.swicher-wrapper .checkboxParam {
  margin-bottom: 8px;
  display: flex;
}

.CheckBoxSwitcher {
  background: #eeeeee;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 20px;
  width: 76px;
  min-width: 76px;
  height: 32px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.CheckBoxSwitcher ~ label {
  padding-left: 19px;
  display: inline-block;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.CheckBoxSwitcher.checked {
  background: #ffdc1c;
  border: 1px solid #f5d210;
}

.CheckBoxSwitcher.checked::after {
  margin-left: 47px;
}

.CheckBoxSwitcher:not(.checked)::after {
  margin-left: 7px;
}

.CheckBoxSwitcher,
.CheckBoxSwitcher::after {
  transition: all 0.2s;
}

.CheckBoxSwitcher::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 20px;
  width: 22px;
  height: 22px;
}

/* CREATE ORDER/OFFER PAGE*/

.create-page {
  position: relative;
  padding-left: 28px;
  padding-right: 28px;
  margin-left: -28px;
  margin-right: -28px;
}

@media (max-width: 768px) {
  .create-page .header-statusBar > div {
    text-align: center;
  }
}

.create-page .header-statusBar {
  align-items: center;
  background: #f7f7f7;
  border: 1px solid #dddddd;
  border-width: 1px 0 1px 0px;
  margin-left: -28px;
  margin-right: -28px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}

.create-page .header-statusBar span {
  line-height: 15px;
  font-size: 14px;
}

.create-page .carInfo {
  align-content: flex-start;
}

@media (max-width: 480px) {
  .create-page .header-statusBar {
    justify-content: center;
  }
  .create-page .header-statusBar .active {
    max-width: 146px;
  }
  .create-page .header-statusBar > div {
    width: auto !important;
    padding: 13px 0 !important;
  }
  .header-statusBar > div .circle {
    display: inline-block;
    margin: 0 7px;
  }
  .header-statusBar > div svg {
    margin: 0 7px;
  }
  .header-statusBar > .go-prev .circle::after {
    background-color: #a038e3;
  }
  .header-statusBar > .go-prev svg path,
  .header-statusBar > .go-next svg path {
    stroke: #9509ef;
  }
  .header-statusBar > div .circle::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #999;
  }
}

.create-page .header-statusBar > div {
  width: 25%;
  position: relative;
  padding: 13px 15px;
  line-height: 17px;
}

.create-page .header-statusBar > div span {
  cursor: pointer;
}

.create-page .header-statusBar > div.active {
  color: #9509ef;
  padding: 13px !important;
}

.create-page .header-statusBar > div:not(.active) {
  color: #909090;
}

.create-page .header-statusBar > div.active svg path {
  stroke: #9509ef;
}

.create-page .header-statusBar > div svg {
  vertical-align: middle;
}

@media (min-width: 481px) {
  .create-page .header-statusBar > div svg {
    position: absolute;
    right: -5px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.select-filter-payment {
  max-width: calc(100% - 71px);
}

.create-page .select * {
  font-size: 14px;
  line-height: 16px;
}

.box-grooz-wrapper-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -13px;
  margin-right: -13px;
  margin-bottom: 25px;
  justify-content: space-between;
}

.box-grooz-wrapper {
  margin-top: 15px;
}

.create-page .box-grooz.active,
.create-page .box-grooz:hover {
  background: #ffdc1c;
  border: 1px solid #ffd12c;
}

.create-page .box-grooz:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
}

@media (max-width: 479px) {
  .create-page .box-grooz {
    height: 100px;
  }
  .box-grooz-wrapper {
    min-width: 50% !important;
  }
}

@media (max-width: 799px) {
  .box-grooz-wrapper-row > * {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
}

@media (min-width: 480px) and (max-width: 799px) {
  .create-page .box-grooz {
    height: 120px;
  }
  .box-grooz-wrapper {
    min-width: 33.33% !important;
  }
}

@media (min-width: 800px) {
  .create-page .box-grooz {
    height: 120px;
  }
  .box-grooz-wrapper {
    max-width: 180px !important;
    padding: 0 10px !important;
  }
}

.create-page .box-grooz {
  cursor: pointer;
  position: relative;
  background: #f7f7f7;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-page .box-grooz span {
  font-size: 14px;
  word-spacing: 9990px;
  word-wrap: break-word;
}

.typeGrooz > div {
  margin-top: 15px;
}

.typeGrooz .typeName {
  color: #909090;
  font-size: 14px;
}

.typeGrooz .rowParams {
  width: 100%;
}

.typeGrooz .standartParams {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 15px;
}

.typeGrooz .standartParams > *,
.typeGrooz .moreParams > * {
  padding-bottom: 15px;
}

.valumeCalculate {
  display: inline-block;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.steps-create {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.step-create:not(:last-child) {
  padding-top: 20px;
}

.step-create {
  padding-bottom: 50px;
  max-width: 100%;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 50px;
}

/*Animation Width*/

.steps-create > .step-create.active {
  opacity: 1;
  margin-left: 0px;
  transition: all 0.3s;
}

.steps-create > .step-create:not(.active) {
  max-height: 0;
  transition: all 0.3s;
}

.steps-create > .step-create.active {
  max-height: 1000000000000px;
  transition: all 0.3s;
}

.steps-create > .step-create:not(.active):not(.deactive) {
  opacity: 0;
  margin-left: 0px;
  overflow: hidden;
  transition: all 0.3s;
  pointer-events: none;
}

.steps-create > .step-create.deactive {
  margin-left: -100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.select175px {
  width: 187px;
}

.row-parametrs {
  align-items: center;
}

.cheked-list {
  flex: 0 0 100%;
  max-width: 100%;
}

.swicher-wrapper {
  align-items: flex-start;
}

.cheked-list > div {
  display: flex;
}

.row-parametrs > * {
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 576px) {
  .page-block {
    padding: 10px 15px;
    padding-top: 0;
  }
}

@media (min-width: 1024px) {
  .page-block {
    padding: 0 50px;
  }
}

@media (max-width: 1024px) {
  .page-block {
    padding: 0 15px;
  }
}

.user-page {
  padding-top: 25px;
  min-height: calc(100vh - 430px);
}

.user-page .avatar-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.title-auto {
  font-size: 16px;
}

.auto-template {
  border-bottom: 1px solid #dddddd;
  margin-left: -28px;
  margin-right: -28px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 20px;
}

.flex-columns {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 80px;
  font-size: 12px;
}

.flex-columns .left-angle {
  margin: 0 25px;
}

.requests-article-block,
.dialogs-block {
  overflow: hidden;
}

.review-article hr,
.requests-article-block .request-article hr,
.dialogs-block .dialog hr {
  position: absolute;
  width: 300%;
  background-color: #dddddd;
  height: 1px;
  border: none;
  left: -100%;
  bottom: 0px;
}

.href-hover {
  color: #6c6c6c;
}

.href-hover:hover {
  color: #ad2dff !important;
}

.settings-notification .row {
  margin-left: -15px;
  margin-right: -15px;
}

.settings-notification .row > div {
  padding-left: 15px;
  padding-right: 15px;
}

.table {
  width: 100%;
  text-align: left;
}

.pay-history {
  min-width: 712px;
  min-height: 200px;
}

@media (min-width: 576px) {
  .user-avatar-wrapper {
    max-width: 80px !important;
  }
}

.user-avatar {
  width: 46px;
  object-fit: cover;
  height: 46px;
}

table {
  border-collapse: collapse;
  border-spacing: 0 1em;
}

.pay-history .table thead tr,
.pay-history .table tbody tr {
  height: 34px;
}

.pay-history .table thead tr,
.pay-history .table tbody tr:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}

.pay-history .table thead * {
  font-size: 12px;
  line-height: 14px;
  color: #909090;
  font-weight: normal;
}

.pay-history .table tbody * {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.pay-history .row > .col:last-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pay-history .date {
  word-break: break-all;
}

.pay-history > .row > div {
  padding: 10px 15px;
  font-size: 14px;
}

.pay-history > .row.header-table {
  color: #909090;
}

.pay-history > .row:not(.header-table)::before {
  content: "";
  border-top: 1px solid #dddddd;
  width: calc(100% - 30px);
  margin: auto;
}

@media (max-width: 436px) {
  .card-list {
    overflow-x: scroll;
  }
}

.card-list {
  margin-left: -8px !important;
  margin-right: -8px !important;
  padding: 20px 0;
  position: relative;
  max-width: 100% !important;
}

.card.active > .row {
  background: #ffdc1c;
}

.card {
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 20px;
}

.card > .row {
  padding: 21px 6px;
  width: 380px;
  max-width: 380px !important;
  height: 220px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 13px;
}

@media (min-width: 769px) {
  .list-type-auto-wrapper {
    max-width: calc(100% - 200px);
    min-width: 33.3333333333%;
  }
}

.list-type-auto-wrapper {
  margin-left: -15px;
  margin-right: 15px;
  padding: 0 15px;
}

.list-type-auto-wrapper h4 {
  padding: 0 15px;
}

.list-type-auto {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.list-type-auto > div {
  padding: 0 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.card .card-hidden {
  height: 32px;
  margin: auto;
  background: #f7f7f7;
}

.card-list .add-new {
  position: absolute;
  right: 0;
  bottom: -30px;
}

.input-name-cart input {
  font-size: 18px;
}

.input-name-cart {
  margin-top: 17px;
}

.input-name-cart input,
.input-cart-wrapper input {
  border: none;
  outline: none;
  width: 100%;
  height: 26px;
  border-bottom: 1px solid #000;
}

.input-cart-wrapper {
  display: flex;
}

.input-cart-wrapper input {
  font-size: 20px;
}

.not-border {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000;
}

.expires input {
  height: 24px;
  font-size: 18px;
  text-align: center;
}

.dialogs-block .dialog .last-message {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 25px;
}

.dialogs-block .dialog .last-message .text > span {
  position: relative;
  padding: 3px 5px;
  margin-left: -5px;
  margin-right: -5px;
  width: 100%;
  display: block;
  padding-right: 40px;
  color: #322f2f;
}

.dialogs-block .dialog .last-message .counter {
  position: absolute;
  right: 9px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 19px;
  width: max-content;
  padding: 0 6px;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #a038e3;
}

.dialogs-block .dialog.unread .last-message {
  background-color: #f7f7f7;
  border-radius: 5px;
}

.dialogs-block .dialog .text .img-from {
  height: 23px;
  max-width: 38px;
  object-fit: cover;
}

.dialog-user-name {
  font-size: 14px;
}

.messages-block .message-content {
  padding-right: 30px;
}

.dialog .dialog-content,
.messages-block .dialog-content {
  overflow: hidden;
  padding-left: 5px !important;
}

.messages-block .recentMessage {
  padding: 10px 0;
  margin-left: 0;
}

.dialog .dialog-content .head,
.dialog .dialog-content .head-message {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dialogs-block .dialog .last-message {
  position: relative;
}

.dialog .dialog-content .head,
.dialogs-block .dialog .last-message {
  padding-left: 7px;
}

.dialog .dialog-content .head > * {
  display: inline-block;
  width: max-content;
}

.dialog .dialog-content .head .dialog-user-name {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #171717;
}

.dialog .dialog-content .head > div:last-child {
  float: right;
}

@media (min-width: 552px) {
  .dialog .with-article {
    max-width: 120px;
  }
  .messages-block .avatar-message {
    max-width: 76px;
  }
}

.messages-block .avatar-message {
  flex-basis: 0;
  flex-grow: 1;
}

.user-avatar-image-block {
  line-height: 16px;
}

.user-avatar-image-block .user-avatar {
  margin-right: 15px;
}

.user-avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media (max-width: 551px) {
  .messages-block .avatar-message {
    max-width: 20px;
  }
  .dialog .with-article * {
    display: inline-block;
    padding-right: 5px;
    margin-bottom: 5px;
  }
  .dialog-user-name {
    max-width: 158px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .last-message .text span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.last-message .text span a {
  color: inherit;
}

/*MessageChat*/

.tab_group .action-counter {
  top: -1px;
  right: -5px;
}

.online {
  color: #b9b9b9;
  font-size: 14px;
  display: inline-block;
}

.chat-header > * {
  display: inline-block;
}
.chat-header .username-dialog {
  color: inherit;
}
.user-page .review-article:first-child {
  border-top: 1px solid #ddd;
}
.description-chat {
  background: #f9f8f8;
  margin-left: -28px !important;
  margin-right: -28px !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  border-top: 1px solid #dddddd;
}

.chat-header {
  position: relative;
}

.chat-header hr {
  width: calc(100% + 56px);
  display: block;
  height: 1px;
  background-color: #dddddd;
  margin-left: -28px !important;
  margin-right: -28px !important;
  border: none;
}

.message-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 174px);
  flex-direction: column;
  position: relative;
}

.messages-block {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.messages-block.padding {
  padding-top: 10px;
}

.messages-block.not-my.padding .message-content {
  padding-bottom: 10px;
}

.messages-block.unread {
  background-color: #f7f7f7;
}

.messages-block .name {
  width: 237px;
}

.messages-block .head-message > * {
  margin-bottom: 10px;
}

.messages-block .time,
.messages-block .name {
  display: inline-block;
  font-size: 14px;
  line-height: 15px;
}

.messages-block .time {
  color: #6c6c6c;
}

.time-tag {
  align-items: center;
  width: 100%;
  margin: 0;
}

.time-tag .col {
  height: 1px;
  background-color: #dddddd;
}

.time-tag span {
  display: inline-block;
  padding: 0 30px;
  color: #6c6c6c;
}

.reading-status {
  color: #909090;
  font-size: 14px;
}

.reading-status:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis 0.9s steps(4) infinite;
  animation: ellipsis 0.9s steps(4) infinite;
  content: "\2026";
  width: 0;
}

.reply-message {
  opacity: 0;
}

.messages-block {
  cursor: pointer;
}

.messages-block.selected {
  background-color: #ffe17654;
}

.messages-block:hover .reply-message {
  opacity: 1;
  transition: all 0.3s;
}

.progress-bar {
  min-width: 100px;
  background-color: #e6e6e6;
  border-radius: 5px;
}

.progress-bar .barLine {
  height: 15px;
  border-radius: 2px 0 0 2px;
  transition: width 100ms linear;
  background-position-x: 0;
  background-position-y: 0;
  animation: uploadProgressAnimation 1s linear infinite;
}

@keyframes uploadProgressAnimation {
  0% {
    background-position-x: 0;
  }
  to {
    background-position-x: 28px;
  }
}

.loading-area .attach-item {
  display: inline-flex;
  align-items: center;
  max-width: 253px;
}

.attach-item .file-size,
.attach-item .file-name {
  padding: 0 5px;
  font-size: 14px;
  color: #6c6c6c;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.files-area .attach-item {
  display: inline-flex;
  position: relative;
  width: max-content;
}

.step-create .cargoPhotos div {
  position: relative;
  display: inline-block;
  width: 119px;
  height: 95px;
  margin: 5px;
}

.cargoPhotos img:not(.attachDelete) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.step-create .cargoPhotos .attachDelete,
.attach-item .attachDelete {
  position: absolute;
  width: 15px;
  top: 0;
  right: 0;
  cursor: pointer;
}

.attath-area {
  margin: 0 -5px;
}

.attach-item {
  margin: 10px 5px;
}

.attath-area:not(.files-area) .attach-item {
  position: relative;
  width: max-content;
  display: inline-block;
}

.attath-area .typeImg {
  width: 32px;
  height: 32px;
}

@media (max-width: 551px) {
  .attach-item img.image-message {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 552px) {
  .attach-item img.image-message {
    height: 100px;
    width: 100px;
  }
  .filesAttach {
    margin: 0 40px;
  }
}

.filesAttach {
  max-height: 179px;
  overflow-y: auto;
  overflow-x: hidden;
}

.attach-item img.image-message {
  border-radius: 5px;
  object-fit: cover;
  background-color: #c4c4c4;
}

.reply-message,
.error-retry {
  position: absolute;
  top: 9px;
  cursor: pointer;
  width: 30px;
}

@media (max-width: 768px) {
  .emoji-mart,
  .input-chat .smiles {
    display: none !important;
  }
}

@media (max-width: 551px) {
  .reply-message,
  .error-retry {
    right: -10px;
  }
}

@media (min-width: 552px) {
  .reply-message,
  .error-retry {
    right: 15px;
  }
}

.error-retry {
  display: flex;
  align-items: center;
}

.reply-message svg path {
  fill: #c670fd;
}

.error-retry .action-menu {
  opacity: 0;
  pointer-events: none;
}

.error-retry:hover .action-menu {
  opacity: 1;
  pointer-events: all;
  transition: all 0.3s;
}

.action-menu > div {
  padding: 5px 10px;
}

.action-menu > div:hover {
  background-color: #f7f7f7;
}

.action-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ffd12c;
  border-radius: 5px;
  top: 100%;
  overflow: hidden;
  right: 0;
  width: 160px;
  font-size: 12px;
  z-index: 2;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.message-content,
.head-message {
  padding: 0 9px;
}

.head-message {
  display: flex;
}

.dialog-wrap .scroll > div:nth-child(2) {
  pointer-events: none;
}

.head-message .name {
  max-width: 130px;
}

.recentMessage .recentContent .name,
.head-message .name {
  font-size: 14px;
  line-height: 16px;
  color: #171717;
  font-weight: 700;
}

.message-content {
  position: relative;
}

.messages-block .message-content,
.recentMessage .recentContent .message-content {
  color: #322f2f;
  font-size: 14px;
  word-wrap: break-word;
}

.recentMessage .recentContent .message-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
}

/*DIALOG SCROLL */

.scroll .track-vertical {
  width: 7px;
  background-color: none;
  position: absolute;
  right: 0;
  height: 100%;
  transition: all 0.2s;
  padding: 0 2px;
  z-index: 4;
  opacity: 0;
}

.scroll .track-vertical:hover {
  width: 12px;
  padding: 0 2.5px;
}

.scroll .track-vertical:hover .thumb-vertical {
  width: 7px;
}

.scroll .thumb-vertical {
  transition: width 0.2s;
  width: 3px;
  background-color: #999;
  border-radius: 5px;
}

.dialog-wrap {
  width: 100%;
  position: relative;
  display: flex;
  flex: 1 1;
}

.dialog-wrap .dialog-typers {
  padding: 6px 0;
}

.dialog-wrap .dialog > div {
  margin-top: auto;
}

.dialog-wrap > .dialog {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: 100%;
  display: block;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  transition: height 0.2s;
}

.dialog-wrap .scroll > div:not(.track-vertical) {
  display: block;
  flex-direction: column;
  max-height: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.dialog-wrap .load-message {
  position: fixed;
  right: 20px;
  top: 100px;
  opacity: 0;
  pointer-events: none;
  width: 50px;
  transition: opacity 0.3s;
}

.dialog-wrap .load-message.active {
  opacity: 1;
  transition: opacity 0.3s;
}

.scroll-to-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  right: 20px;
  bottom: 100px;
  z-index: 3;
  background-color: #fff;
  position: absolute;
  cursor: pointer;
}

.scroll-to-bottom.active {
  transform: translateY(0) !important;
  transition: transform 0.1s ease-in !important;
}

.scroll-to-bottom {
  transform: translateY(70px) !important;
  transition: transform 0.1s ease-in !important;
}

/* Iput Panel */

.input-chat canvas {
  height: 48px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 26px;
  padding-left: 46px;
  padding-right: 42px;
  width: 100%;
}

.input-chat .canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  padding-left: 46px;
  padding-right: 42px;
}

.input-chat .canvas-overlay > div {
  height: 48px;
  width: 100%;
}

.input-chat .timer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  height: 17px;
  font-size: 12px;
}

.voice-sound .play,
.voice-sound .pause,
.input-chat .RecordPauseStart {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
  z-index: 3;
  height: 32px;
}

.single-char {
  max-width: 60px !important;
  text-align: center;
}

.user-column-2 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  align-items: flex-start;
}

.user-column-2 .input-action {
  margin: 10px 15px;
}

@media (max-width: 992px) {
  .user-column-2 {
    text-align: center;
    justify-content: center;
  }
}

.message-panel > *:first-child,
.message-panel > *:last-child {
  margin-top: 10px;
}

.message-panel {
  position: relative;
  z-index: 4;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background: #f9f8f8;
  padding: 7px 28px;
  padding-bottom: 32px;
}

.message-panel > .panel {
  position: relative;
  display: flex;
  align-items: flex-start;
  align-content: center;
}

.recentMessage .recentContent .name {
  padding-bottom: 5px;
  color: #c670fd;
}

.message-panel .recentMessage {
  width: auto;
  pointer-events: none;
  padding-bottom: 10px;
}

.recentMessage .recentContent {
  border-left: 2px solid #c670fd;
  padding-left: 10px;
}

.input-chat {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 26px;
  border: 1px solid #b9b9b9;
}

.input-chat * {
  z-index: 2;
}

.lk-page.messages ~ footer {
  display: none;
}

.recentMessage,
.input-chat {
  margin: 0 40px;
}

.sendAndMicro,
.sendRecord {
  width: 26px;
  height: 26px;
}

.sendRecord,
.sendAndMicro {
  right: 0;
}

.addMoreAttach {
  width: 24px;
}

.resetRecord,
.sendRecord,
.sendAndMicro,
.addMoreAttach {
  top: 10px;
  position: absolute;
}

.sendAndMicro img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.input-chat .input-message {
  width: 100%;
  padding: 13px 100px 13px 22px;
  background-color: #fff;
  border-radius: 26px;
  font-size: 14px;
  color: #000;
  max-height: 48px;
  resize: none;
  border: none;
  min-height: 48px;
  overflow: auto;
  height: max-content;
  word-break: break-all;
}

/* .input-chat::after {
  content: "";
  left: -1px;
  top: -1px;
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: 0;
  background-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 12px;
} */

.input-chat .smiles,
.input-chat .photo {
  position: absolute;
  top: 16px;
  cursor: pointer;
}

.input-chat .smiles {
  right: 23px;
}

.input-chat .photo {
  right: 68px;
}

.input-chat .placeholder {
  position: absolute;
  top: -3px;
  bottom: 0;
  height: 14px;
  left: 22px;
  margin: auto;
  font-size: 14px;
  line-height: 16px;
  color: #322f2f;
  pointer-events: none;
}

.left-tools * {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

/*Panel Items*/

.erase-animation-item-enter {
  opacity: 0;
  transform: scale(0.1);
}

.erase-animation-item-enter.erase-animation-item-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 0.1s;
}

.erase-animation-item-leave {
  opacity: 1;
  transform: scale(1);
}

.erase-animation-item-leave.erase-animation-item-leave-active {
  opacity: 0;
  transform: scale(0.1);
  transition: all 0.1s;
}

/* Audio Voice */

.voice-sound {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 26px;
}

.voice-sound.active .play {
  display: none;
}

.voice-sound:not(.active) .pause {
  display: none;
}

/* Audio */

.message-sounds {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.message-sound .svg-wrapper {
  max-width: 450px;
}

.message-sound .svg-wrapper svg {
  width: 100%;
  height: 30px;
}

.voice-sound .svg-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  border-radius: 26px;
  padding-left: 50px;
  padding-right: 50px;
}

.voice-sounds-duration {
  position: absolute;
  right: 5px;
  top: 12px;
  font-size: 14px;
  color: #6c6c6c;
}

.voice-sound .svg-wrapper svg {
  width: 100%;
  height: 40px;
}

.message-sound {
  position: relative;
  width: 100%;
  padding-top: 10px;
  margin-top: 5px;
  padding-bottom: 8px;
  margin-bottom: 5px;
  padding-right: 15px;
}

.message-sound .play,
.message-sound .pause {
  position: absolute;
  top: 8px;
  left: 0px;
}

.message-sound:not(.active) .pause {
  display: none;
}

.message-sound.active .play {
  display: none;
}

.message-sound.active .pause {
  display: block;
}

.message-sound .message-sound-info {
  display: block;
  padding: 0 5px;
  padding-left: 40px;
}

.message-sound .canvas-wrapper svg {
  width: 100%;
}

.message-sound .message-sounds-duration {
  width: 100%;
  font-size: 12px;
  color: #999999;
  margin: 0;
  line-height: 24px;
  display: inline-block;
}

.message-sound .sound-canvas {
  height: 30px;
}

.message-sound .message-sounds-name {
  width: 100%;
  font-size: 14px;
  color: #667788;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}

.message-sound .message-sound-range {
  position: absolute;
  height: 3px;
  bottom: 0;
  background-color: #99d2fc;
  width: 100%;
  display: none;
}

.message-sound .message-sound-range:hover {
  position: absolute;
  height: 8px;
  background-color: #99d2fc;
  width: 100%;
}

.message-sound .message-sound-range .range-position {
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 0%;
  background-color: #008ff7;
}

.message-content .message-images.more {
  height: 237px;
}

.message-content .message-images.one {
  height: 350px;
}

.message-content .message-images.two {
  height: 300px;
}

.message-content .message-images {
  max-width: 450px;
  margin: 1px -5px -5px;
  display: grid;
  grid-gap: 5px;
}

.message-content .message-images:not(.two) {
  grid-template-columns: repeat(3, 1fr);
}

.message-content .message-images.two {
  grid-template-columns: repeat(4, 1fr);
}

/* GRID IMAGE MESSAGES */

.message-content .message-images.more .image:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.message-content
  .message-images.more
  .image:not(:first-child):not(:last-child) {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.message-content .message-images.more .image:last-child {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
}

.message-content .message-images.one .image {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
}

.message-content .message-images.two .image:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

.message-content .message-images.two .image:last-child {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
}

/* GRID IMAGE MESSAGES */

.message-content .message-images img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #eff4f8;
  position: relative;
}

.message-files .message-file img {
  margin-right: 10px;
}

.message-files .message-file .file-info .name {
  font-size: 14px;
  line-height: 16px;
  color: #9509ef;
}

.message-files .message-file .file-info .size {
  font-size: 14px;
  line-height: 16px;
  color: #6c6c6c;
}

.message-files .message-file {
  display: flex;
  padding: 10px 0;
  position: relative;
  width: max-content;
  max-width: 100%;
}

.message-images .image-blur {
  position: relative;
  height: 100%;
}

/* .message-images .image .image-blur img {
  -webkit-filter: blur(2px) brightness(0.7);
  filter: blur(2px) brightness(0.7);
} */

.message-images .image-blur span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 35px;
  color: #fff;
  z-index: 1;
  font-weight: 600;
  font-size: 20px;
}

/*Emoji */

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  position: absolute;
  top: -318px;
  right: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform;
  /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  display: none;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 7px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 3;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI",
    "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999a9c;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened
  .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */

.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */

.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

.emoji-animation-item-enter {
  opacity: 0;
}

.emoji-animation-item-enter.emoji-animation-item-enter-active {
  opacity: 1;
  transition: all 0.1s;
}

.emoji-animation-item-leave {
  opacity: 1;
}

.emoji-animation-item-leave.emoji-animation-item-leave-active {
  opacity: 0;
  transition: all 0.1s;
}

.react-dadata__container {
  padding: 0;
}

.react-dadata__input {
  border-radius: 50px !important;
}

.react-dadata__suggestion-note {
  display: none !important;
}

.textarea.errRequired,
.input input.errRequired,
.inputPhoto.errRequired,
.errRequired > input,
.errRequired > .input-date {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(221, 30, 30);
  -moz-box-shadow: 0px 0px 5px 1px rgba(221, 30, 30);
  box-shadow: 0px 0px 5px 1px rgba(221, 30, 30);
}

.errRequired,
.errRequired .react-dadata__input {
  border-color: transparent !important;
}

.addPhotoCarBlock {
  justify-content: center;
  align-self: start;
}

.inputPhoto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inputPhoto {
  display: inline-block;
  position: relative;
}

.inputPhoto .placeholder {
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 17px;
  color: #fff;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
}

.inputPhoto.unload .placeholder,
.inputPhoto .placeholder:hover {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.inputPhoto .placeholder span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 24px;
  text-align: center;
}

.article-block .car-description {
  word-wrap: break-word;
  color: #171717;
  font-size: 14px;
}

span.errRequired {
  box-shadow: 0px 0px 5px 1px rgba(221, 30, 30);
}

.cargoPhotos {
  margin-left: -5px;
  margin-right: -5px;
}

#root {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
}

#root > * {
  width: 100%;
  position: relative;
}

.moreInfoCheckBox {
  margin: 0 -15px;
  align-self: flex-start;
}

label {
  -moz-user-select: none;
  /* Mozilla Firefox */
  -ms-user-select: none;
  /* Internet Explorer (не поддерживается) */
  -o-user-select: none;
  /* Opera Presto (не поддерживается) */
  -webkit-user-select: none;
  /* Google Chrome, Opera Next, Safari */
}

.moreInfoCheckBox > div {
  padding: 0 15px;
}

.react-tel-input .flag-dropdown {
  display: none;
}

.carDescription {
  word-break: break-all;
}

.carTemplates-block {
  position: relative;
}

.additionally > span {
  display: block;
  margin-top: 3px;
}

.templatePhoto,
.additionally {
  padding: 0 15px;
}

@media (min-width: 992px) {
  .templatePhoto {
    max-width: 153px;
  }
}

.contractInfoTemplate {
  min-width: 250px;
}

.head-template .title-auto {
  text-overflow: ellipsis;
  overflow: hidden;
}

.head-template > * {
  margin-bottom: 5px;
}

.template-content > * {
  margin-top: 10px;
}

.carSelect input[id^="react-select-"] {
  opacity: 1 !important;
}

@media (max-width: 768px) {
  .complete-modal {
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    width: max-content;
    margin: auto;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(221, 221, 221);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.13) 3px 2px 15px;
    border-radius: 10px;
    padding: 10px;
    padding-top: 25px;
  }
}

@media (min-width: 769px) {
  .complete-modal {
    top: 50%;
    position: absolute;
    left: 50%;
    margin: auto;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(221, 221, 221);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.13) 3px 2px 15px;
    border-radius: 10px;
    padding: 25px 30px 20px;
    transform: translate(-50%, -50%);
  }
}

.pop-block.reviewsForm {
  right: 0;
  left: auto;
}

.reviews-pop {
  position: relative;
}

.reviews-pop > span {
  cursor: pointer;
}

.reviews-pop:not(.open) .pop-block {
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
}

.reviews-pop.open .pop-block {
  opacity: 1;
  pointer-events: all;
}

.cancels-modal,
.dialogs-modal {
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  width: max-content;
  margin: auto;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.13) 3px 2px 15px;
  border-radius: 10px;
  padding: 0;
}

.orders-modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 320px;
  position: absolute;
  width: 320px;
  margin: auto;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.13) 3px 2px 15px;
  border-radius: 10px;
  padding: 0;
}

.orders-item > div {
  justify-content: center;
}

.delivered-executors > div,
.orders-item > div,
.cancels-modal > div,
.dialogs-modal > div {
  border-radius: 10px;
}

.orders-item > div,
.delivered-executors > div,
.cancels-executors > div,
.dialogs-executors > div {
  position: relative;
  display: flex;
  cursor: pointer;
  padding: 5px 10px;
  align-items: center;
}

.orders-item > div:hover,
.delivered-executors > div:hover {
  background-color: #f7f7f7;
}

.orders-item > div:hover,
.cancels-executors > div:hover,
.dialogs-executors > div:hover {
  background-color: #ffdc1c;
}

.delivered-executors > div .name,
.cancels-executors > div .name,
.dialogs-executors > div .name {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.adress-select {
  position: relative;
}

.adress-select svg {
  position: absolute;
  right: 13px;
  top: 16px;
  margin: auto;
  cursor: pointer;
}

.adress-select input {
  padding-right: 28px;
}

.MuiFormHelperText-filled {
  display: none;
}

.review-article .user-avatar {
  margin-right: 15px;
}

.load-content {
  min-height: 60vh;
}

.static-page {
  min-height: 53vh;
}

.static-page ul,
.about-page ul:not(.head-list) {
  color: #6c6c6c;
  list-style: inside;
}

.static-page ol {
  list-style: none;
  counter-reset: li;
}

.static-page ol li::before {
  content: counter(li);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #6c6c6c;
}

.static-page ol li {
  counter-increment: li;
}

.static-page ol {
  color: #6c6c6c;
}

.static-page h1 {
  font-size: 24px;
  font-weight: normal;
  line-height: 28px;
  color: #171717;
  margin: 45px 0 30px 0;
}

.static-page h2 {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #171717;
}

.static-page h3 {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #171717;
}

.static-page a {
  font-size: 16px;
  color: #171717;
  text-decoration: underline;
}

.static-page p {
  font-size: 14px;
  color: #6c6c6c;
}

.static-page .content {
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 50px;
}

.user-avatar-image-block {
  position: relative;
}

@keyframes ld-breath {
  0% {
    animation-timing-function: cubic-bezier(0.9647, 0.2413, -0.0705, 0.7911);
    transform: scale(0.9099999999999999);
  }
  51% {
    animation-timing-function: cubic-bezier(0.9226, 0.2631, -0.0308, 0.7628);
    transform: scale(1.2994);
  }
  100% {
    transform: scale(0.9099999999999999);
  }
}

.fast-access-btn.notifications {
  word-break: break-all;
}

.geo-active {
  position: fixed !important;
  display: flex;
  align-items: center;
  right: 28px;
  max-width: 58px;
  height: 57px;
  bottom: 100px;
  transition: all 0.5s;
  z-index: 1;
  overflow: hidden;
  background: #eeeeee;
  border: 1px solid #eeeeee;
  box-shadow: 9px 4px 15px rgba(64, 61, 58, 0.18);
  border-radius: 30px;
}

.geo-active:hover {
  max-width: 250px;
}
.geo-active:hover div {
  pointer-events: all;
}
.geo-active div {
  position: absolute;
  font-size: 14px;
  line-height: 15px;
  color: #4f4f4f;
  width: 212px;
  transform: translateX(800px);
  transition: all 0.2s;
  pointer-events: none;
}

.geo-active:hover div {
  opacity: 1;
  transform: translateX(46px);
}
.geo-active a {
  color: #9509ef;
}
.geo-active:hover {
  animation: none;
}

.geo-active svg {
  margin-left: 18px;
  width: 20px;
  height: 28px;
  animation: ld-breath 1s infinite linear;
}

.verified-field .input-error-label {
  width: calc(100% - 48px);
  padding-left: 0px;
}

.isDemoUsed.tariff-wrapper .tariff {
  background-color: #f7f7f7;
}

.isDemoUsed.tariff-wrapper .button-fill::before {
  display: none;
}

.isDemoUsed.tariff-wrapper .button-fill {
  background-color: #eeeeee !important;
  border: none;
}

.name-organization {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name-organization:hover ~ .full-name {
  opacity: 1;
  z-index: 1;
}
.short-value:hover ~ .full-value {
  opacity: 1;
  z-index: 1;
}
.short-value ~ .full-value {
  position: absolute;
  width: max-content;
  top: 20px;
  right: -3px;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s;
  z-index: 3;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: rgba(0, 0, 0, 0.13) 3px 2px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  overflow: hidden;
}
.name-organization ~ .full-name {
  position: absolute;
  top: 50px;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s;
  z-index: 3;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: rgba(0, 0, 0, 0.13) 3px 2px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  overflow: hidden;
}

.cookie-notification {
  position: fixed !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px !important;
  background-color: #fff;
  height: 90px;
  bottom: 0;
  left: 0;
  z-index: 4;
  border-top-right-radius: 28px;
  padding: 10px 15px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.13) 3px 2px 15px;
}

.cookie-notification .button-fill span {
  line-height: 14px;
}

.reviews-modal a {
  color: #171717;
}

#review-show,
.reviews-modal {
  width: 366px;
  max-height: 250px;
  overflow-y: scroll;
}

#review-show .angle-toogle {
  padding: 5px 0;
  padding-left: 10px;
}
